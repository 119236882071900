<template>
  <div class="box-container">
    <v-row
      justify="center"
      align="center"
    >
      <v-col
        cols="12"
        xl="8"
        lg="12"
      >
        <div class="d-flex justify-space-between flex-wrap mb-5">
          <div class="d-flex align-center">
            <v-avatar
              tile
              size="25"
              class="me-3"
            >
              <img
                src="@/assets/images/icons/credit-card_filled.svg"
                alt=""
              >
            </v-avatar>
            <h2 class="mb-0">
              {{ $t("Revenu") }}
            </h2>
          </div>
          <div class="d-flex">
            <h1 v-if="wallet">
              {{ $t("Revenu") }}: &euro;{{ parseFloat(wallet.amount.replace(",",".")) }}
            </h1>
          </div>
        </div>
        <v-row>
          <v-col
            v-if="!kycvalidated"
            cols="12"
          >
            <v-alert
              type="warning"
              dismissible
            >
              <h5
                id="exampleModalLabel"
                class="modal-title"
              >
                {{ $t("Attention informations supplémentaire manquantes!") }}
              </h5>
              <p>
                {{ $t("Vous n'avez envoyé vos pièces justificatives à notre partenaires financier Mangopay !\nVous devez d'abord justifier de votre identité auprès de Mangopay pour ensuite réaliser tous vos futurs virements depuis votre porte-monnaie Planète-Market vers votre Compte Banque.\nPour cela, vous devez prendre en photo (ne pas scanner) votre Passeport ou prendre en photo en recto-verso votre Pièce d'identité et ensuite aller dans modifier mon Profil, aller sur la page KYC Document Sélectionner le KYC Document Type, cliquer sur Créer un KYC télécharger votre photo 1 puis soumettre et le cas échéant, votre photo 2 puis la soumettre\nAttendre ensuite que votre compte a bien été Vérifié par Mangopay\nEnsuite vous pourrez effectuer transférer tous les fonds de Porte-monnaie Planète-Market vers votre compte banque") }}
              </p>
            </v-alert>
          </v-col>
          <v-col cols="12">
            <v-card :loading="loading">
              <v-card-title>
                {{ $t("Récapitulatif des transactions") }}
                <v-spacer />
                <v-dialog
                  v-model="dialog"
                  max-width="600px"
                >
                  <template v-slot:activator="{ on, attrs }">
                    <v-btn
                      v-bind="attrs"
                      color="primary"
                      v-on="on"
                    >
                      {{ $t("Nouveau versement") }}
                    </v-btn>
                  </template>
                  <base-card>
                    <v-card-title>
                      {{ $t("Nouveau versement") }}
                      <v-spacer />
                      <v-btn
                        class="mt-4"
                        absolute
                        right
                        icon
                        color="secondary"
                        text
                        @click="dialog = false"
                      >
                        <v-icon>mdi-close</v-icon>
                      </v-btn>
                    </v-card-title>
                    <v-divider />
                    <v-card-text>
                      <v-row>
                        <v-col cols="12">
                          <v-alert
                            dismissible
                            type="info"
                            outlined
                            text
                          >
                            {{
                              $t("Il existe une limite KYC sur les Pay-Outs afin de lutter contre la fraude, le blanchiment d'argent et le financement du terrorisme. Vous devez envoyer certains documents par l'intermédiaire de l'API. C'est obligatoire pour tout montant de paiement.")
                            }}
                          </v-alert>
                        </v-col>
                        <v-col cols="12" />
                        <v-col
                          cols="12"
                          class="py-0"
                        >
                          <p class="text-14 mb-1">
                            {{ $t("Compte bancaire") }} |
                            <router-link to="/bank-accounts">
                              {{
                                $t("Créer un nouveau compte bancaire")
                              }}
                            </router-link>
                          </p>
                          <v-select
                            v-model="payout.bank_account_id"
                            item-value="id"
                            item-text="label"
                            :items="bankaccounts"
                            required
                            placeholder=""
                            outlined
                            dense
                          />
                        </v-col>

                        <v-col
                          cols="12"
                          class="py-0"
                        >
                          <p class="text-14 mb-1">
                            {{ $t("Montant") }}
                          </p>
                          <v-text-field
                            v-model="payout.amount"
                            type="number"
                            :max="parseFloat(wallet.amount.replace(',','.')) - parseFloat(payout.transfer_fee)"
                            required
                            placeholder=""
                            outlined
                            dense
                          />
                        </v-col>
                        <v-col cols="12">
                          <div class=" ">
                            <div>
                              <v-simple-table width="100%">
                                <tbody>
                                  <tr>
                                    <td colspan="2">
                                      {{ $t("Frais de transfère") }}
                                    </td>
                                    <td style="text-align: right">
                                      € {{ payout.transfer_fee }}
                                    </td>
                                    <!--                                    <td hidden>
                                      free pour cee / particulier
                                    </td>
                                    <td hidden>
                                      0.58 € pour GBP
                                    </td>
                                    <td hidden>
                                      2.5 other
                                    </td>-->
                                  </tr>
                                </tbody>
                              </v-simple-table>
                            </div>
                            <p />
                          </div>
                        </v-col>

                        <v-col
                          cols="12"
                          class="d-none"
                        >
                          <p>
                            <label>
                              <input
                                v-model="payout.donation"
                                type="checkbox"
                                name="accept_donation"
                              >
                              <span class="checkmark" />
                              {{ $t("J'accepte de faire un don de 1€(UN EURO) pour") }} <span>''</span>
                            </label>
                            <i class="text-red">{{ $t("(décocher si vous ne souhaitez pas participer)") }}</i>
                          </p>
                        </v-col>
                        <v-col cols="12">
                          <div class="d-flex">
                            <p class="text--bold bold--text text-bold text-body-2 ">
                              {{ $t("Planète Market vous confirme votre transfert de") }}:
                            </p>
                            <span class="me-2" />
                            <p class="primary--text text-body-2 price-shoping-cart text-right text--right">
                              € {{ (payout.amount > 0)?(payout.amount - payout.transfer_fee):0 }}
                            </p>
                          </div>
                        </v-col>
                      </v-row>
                    </v-card-text>
                    <v-divider />
                    <v-card-actions>
                      <v-alert
                        v-if="!kycvalidated"
                        type="warning"
                        dense
                        dismissible
                      >
                        {{ $t("Il manque des informations à votre profil") }}
                        <v-btn
                          depressed
                          color="primary"
                          to="/edit-profile"
                        >
                          {{
                            $t("Mise à jour du profil")
                          }}
                        </v-btn>
                      </v-alert>
                      <v-spacer />
                      <v-btn
                        v-if="kycvalidated"
                        color="primary"
                        :loading="loading"
                        @click="save"
                      >
                        {{ $t("Confirmer") }}
                      </v-btn>
                    </v-card-actions>
                  </base-card>
                </v-dialog>
              </v-card-title>
              <v-divider />

              <v-simple-table>
                <thead>
                  <tr>
                    <th>#</th>
                    <th>{{ $t("Commande n°") }}</th>
                    <th>{{ $t("Total") }}</th>
                    <th>{{ $t("Commission sur les ventes flash") }}</th>
                    <th>{{ $t("Net à tranferer") }}</th>
                    <th>{{ $t("Status") }}</th>
                    <th>{{ $t("Détail") }}</th>
                    <th>{{ $t("Créé le") }}</th>
                  </tr>
                </thead>
                <tbody>
                  <tr v-for="transaction in transactions">
                    <td>{{ transaction.id }}</td>
                    <td>{{ transaction.package_id }}</td>
                    <td>{{ transaction.total }}</td>
                    <td>{{ transaction.commission }}</td>
                    <td>{{ transaction.amount }}</td>
                    <td>{{ transaction.status }}</td>
                    <td>{{ transaction.title }}<br><p v-html="transaction.detail" /></td>
                    <td>
                      {{ transaction.created_at }}
                    </td>
                  </tr>
                </tbody>
              </v-simple-table>
            </v-card>
          </v-col>
        </v-row>
      </v-col>
    </v-row>
    <v-snackbar
      v-model="isSnackbarBottomVisible"
      :color="snackBarColor"
    >
      {{ snackBarMessage }}

      <template v-slot:action="{ attrs }">
        <v-btn
          text
          v-bind="attrs"
          @click="isSnackbarBottomVisible = false"
        >
          {{ $t("Fermer") }}
        </v-btn>
      </template>
    </v-snackbar>
  </div>
</template>

<script>
  import DashbordSidebar from '@/components/DashboardSidebar'
  import { mapState } from 'vuex'

  import { formatDate } from '@/core/utils/filter'
  import ProfileMenu from '../../components/ProfileMenu.vue'
  import PhotoWidget from './PhotoWidget.vue'

  export default {
    components: {
      ProfileMenu,
      DashbordSidebar,
      PhotoWidget,
    },
    data () {
      return {
        isSidebar: false,
        loading: false,
        dialog: false,
        isSnackbarBottomVisible: false,
        snackBarMessage: '',
        snackBarColor: 'success',
        payout: {
          bank_account_id: null,
        },
        bankaccounts: [],
        transactions: [],
        kycvalidated: true,
        wallet: {
          amount: 0,
        },
      }
    },
    computed: {
      ...mapState({
        user: 'user',
        login: 'login',
        displayNotification: 'display_notification',
      }),
      scrollHeight () {
        return this.windowHeight - 48 + 'px'
      },
    },
    created () {
      this.getWallet()
    },
    methods: {
      getWallet () {
        this.loading = true
        // eslint-disable-next-line no-undef
        axios
          .post('/wallet')
          .then((response) => {
            this.loading = false
            if (response.data) {
              this.wallet = response.data.wallet
              this.kycvalidated = response.data.kycvalidated
              if (response.data.bankaccounts) {
                this.bankaccounts = response.data.bankaccounts.map((e) => {
                  return {
                    id: e.Id,
                    label: `${e.Details.IBAN} ${e.OwnerName}`,
                  }
                })
              }

              if (response.data.payout) {
                this.payout = response.data.payout
              }
              if (response.data.transactions) {
                this.transactions = response.data.transactions
              }
            }
          })
          .then(() => {})
      },
      save () {
        console.log(this.payout)
        if (!this.payout.amount) {
          this.toast(this.$t('Le montant est requis'), 'red')
          return
        }

        // console.log(withdrawal, amountavailable)
        if (parseFloat(this.wallet.amount.replace(",",".")) < parseFloat(this.payout.amount - this.payout.transfer_fee)) {
          this.toast(this.$t('Alert montant insufisant') + ' | ' + parseFloat(this.wallet.amount.replace(",",".")) + ' < ' + parseFloat(this.payout.amount - this.payout.transfer_fee), 'red')
          return
        }

        if (
          !confirm(
            this.$t('Vous confirmez le retrait de ') + parseFloat(this.payout.amount - this.payout.transfer_fee) + '€',
          )
        ) {
          return
        }

        this.loading = true
        axios
          .post('/payout', this.payout)
          .then((response) => {
            this.loading = false
            console.log(response)
            this.toast(this.$t('transaction initiée'), 'success')
            this.dialog = false
            this.getWallet()
          // window.location.href = '{{route("payout.wallet_detail", $user->wallet->id)}}';
          //
          })
          .catch((error) => {
            console.log(error)
            this.loading = false
          })
      },

      toast (message, color) {
        this.isSnackbarBottomVisible = true
        this.snackBarColor = color
        this.snackBarMessage = message
      },
    },
  }
</script>
